@@ -1,76 +0,0 @@
<template>
  <v-card class="pa-8">
    <h1 class="text-center">งานของฉัน (ข้อมูล)</h1>
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <span>ข้อมูลลูกค้า</span>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          v-model="form.firstName"
          label="ชื่อจริง"
          outlined
          dense
          hide-details="auto"
          disabled
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          v-model="form.lastName"
          label="นามสกุล"
          outlined
          dense
          hide-details="auto"
          disabled
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          v-model="form.tel"
          label="เบอร์โทรศัพท์"
          outlined
          dense
          hide-details="auto"
          disabled
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          v-model="form.email"
          label="Email"
          outlined
          dense
          hide-details="auto"
          disabled
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          v-model="form.status"
          label="สถานะ"
          outlined
          dense
          hide-details="auto"
          disabled
        ></v-text-field>
      </v-col>
      
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          v-model="form.serviceSubType"
          label="บริการ"
          outlined
          dense
          hide-details="auto"
          disabled
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          v-model="form.serviceType"
          label="ประเภทการบริการ"
          outlined
          dense
          hide-details="auto"
          disabled
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          v-model="form.totalPrice"
          label="ราคารวม *เปลี่ยนแปลงราคาที่นี่"
          outlined
          dense
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          v-model="form.requestDate"
          label="วันที่ให้เข้ารับบริการ"
          outlined
          dense
          hide-details="auto"
          disabled
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          v-model="form.requestTime"
          label="เวลาที่ให้เข้ารับบริการ"
          outlined
          dense
          hide-details="auto"
          disabled
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-autocomplete
          v-model="form.status"
          label="สถานะ"
          :items="[
            'รอการเสนอราคา',
            'รอชำระเงิน',
            'ชำระเงินแล้ว',
            'ยืนยันการชำระเงิน',
            'ปิดงาน',
            'ยกเลิก'
          ]"
          outlined
          dense
          hide-details="auto"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <!-- <p>สถานที่เข้าใช้บริการ</p> -->
        <v-btn color="primary" @click="goMap()">ดูสถานที่เข้ารับบริการ</v-btn>
      </v-col>

      <v-col cols="12" md="12" sm="12">
        <span>ข้อมูลสัตว์เลี้ยง</span>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headersOrder"
            :items="itemsData"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:[`item.action`]="{ item }">
              <v-btn v-if="item.status == 'รอชำระเงิน'" text>
                <v-chip color="orange" dark> ยืนยันรับงาน </v-chip>
              </v-btn>
              <v-btn v-else text>
                <v-chip color="success" dark> แชท </v-chip>
              </v-btn>
            </template>
            <template v-slot:[`item.editItems`]="{ item }">
              <v-icon @click="goToEdit(item)">mdi-pencil</v-icon>
            </template>
            <template v-slot:[`item.petSpecies`]="{ item }">
              {{ item.petSpecies ? item.petSpecies : "-" }}
            </template>
            <template v-slot:[`item.serviceHair`]="{ item }">
              <!-- <v-checkbox
                v-model="item.serviceHair"
                :indeterminate="!item.serviceHair"
                disabled
              ></v-checkbox> -->
              <v-chip
                v-if="item.serviceHair"
                class="ma-2"
                color="green"
                text-color="white"
              >
                <v-icon>mdi-check</v-icon>
              </v-chip>
              <v-chip v-else class="ma-2" color="red" text-color="white">
                <v-icon>mdi-cancel</v-icon>
              </v-chip>
            </template>
            <template v-slot:[`item.serviceCut`]="{ item }">
              <v-chip
                v-if="item.serviceCut"
                class="ma-2"
                color="green"
                text-color="white"
              >
                <v-icon>mdi-check</v-icon>
              </v-chip>
              <v-chip v-else class="ma-2" color="red" text-color="white">
                <v-icon>mdi-cancel</v-icon>
              </v-chip>
            </template>
            <template v-slot:[`item.serviceTom`]="{ item }">
              <v-chip
                v-if="item.serviceTom"
                class="ma-2"
                color="green"
                text-color="white"
              >
                <v-icon>mdi-check</v-icon>
              </v-chip>
              <v-chip v-else class="ma-2" color="red" text-color="white">
                <v-icon>mdi-cancel</v-icon>
              </v-chip>
            </template>
            <template v-slot:[`item.serviceFinger`]="{ item }">
              <v-chip
                v-if="item.serviceFinger"
                class="ma-2"
                color="green"
                text-color="white"
              >
                <v-icon>mdi-check</v-icon>
              </v-chip>
              <v-chip v-else class="ma-2" color="red" text-color="white">
                <v-icon>mdi-cancel</v-icon>
              </v-chip>
            </template>
            <template v-slot:[`item.serviceTooth`]="{ item }">
              <v-chip
                v-if="item.serviceTooth"
                class="ma-2"
                color="green"
                text-color="white"
              >
                <v-icon>mdi-check</v-icon>
              </v-chip>
              <v-chip v-else class="ma-2" color="red" text-color="white">
                <v-icon>mdi-cancel</v-icon>
              </v-chip>
            </template>
            <template v-slot:[`item.serviceSpa`]="{ item }">
              <v-chip
                v-if="item.serviceSpa"
                class="ma-2"
                color="green"
                text-color="white"
              >
                <v-icon>mdi-check</v-icon>
              </v-chip>
              <v-chip v-else class="ma-2" color="red" text-color="white">
                <v-icon>mdi-cancel</v-icon>
              </v-chip>
            </template>
            <template v-slot:[`item.serviceBath`]="{ item }">
              <v-chip
                v-if="item.serviceBath"
                class="ma-2"
                color="green"
                text-color="white"
              >
                <v-icon>mdi-check</v-icon>
              </v-chip>
              <v-chip v-else class="ma-2" color="red" text-color="white">
                <v-icon>mdi-cancel</v-icon>
              </v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>

      <v-col cols="12" md="12" sm="12">
        <span>ข้อมูลร้าน Partner</span>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          v-model="form.partnerFirstName"
          label="ชื่อร้าน"
          outlined
          dense
          hide-details="auto"
          disabled
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          v-model="form.partnerLastName"
          label=""
          outlined
          dense
          hide-details="auto"
          disabled
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          v-model="form.partnerEmail"
          label="Email"
          outlined
          dense
          hide-details="auto"
          disabled
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          v-model="form.partnerTel"
          label="เบอร์โทรศัพท์"
          outlined
          dense
          hide-details="auto"
          disabled
        ></v-text-field>
      </v-col>

      <v-col cols="0" md="3" sm="0"> </v-col>
      <v-col cols="12" md="6" sm="12" class="text-center">
        <span>รูป Slip</span>
        <v-img v-if="showSlip" :src="showSlip" alt="Banner" contain />
      </v-col>
      <v-col cols="0" md="3" sm="0"> </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="text-end">
        <v-btn @click="goBack()">Back</v-btn>
        &emsp;
        <v-btn color="primary" @click="submit()">Save</v-btn>
      </v-col>
    </v-row>

    <!-- Chat Section -->
    <v-divider class="my-4"></v-divider>
    <v-row style="padding: 10px;">
      <v-col cols="12" md="12" sm="12">
        <span><b>คุยกับลูกค้า</b></span>
      </v-col>
    </v-row>
      <v-card class="pa-4">
        <v-list>
          <v-list-item
            v-for="(message, index) in chatMessages"
            :key="index"
            :class="message.chatType === 'partner' ? 'chat-message user-message' : 'chat-message partner-message'"
          >
            <v-list-item-content>
              <v-list-item-title v-if="message.msgType != 'IMG'" style="font-size: 1rem; white-space: break-spaces; line-height: 1;"><b>{{ message.msg }}</b><br> <span style="font-size: 0.5rem !important;">{{ formatDate(message.createdAt) }}</span></v-list-item-title>
              <v-list-item-title v-else style="font-size: 1rem; white-space: break-spaces; line-height: 1;"><v-img v-on:click="onClickImg(message.msg)" :src="message.msg" style="width: 300px; pointer: hover" contain /><br> <span style="font-size: 0.5rem !important;">{{ formatDate(message.createdAt) }}</span></v-list-item-title>
              
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-row>
          <v-col cols="9">
            <v-textarea
              rows="3"
              v-model="newMessage"
              label="พิมพ์ข้อความที่นี่"
              outlined
              dense
            ></v-textarea>
          </v-col>
          <v-col cols="3">
            <v-btn @click="sendMessage('partner')" color="primary" block>ส่งข้อความ</v-btn>
          </v-col>
        </v-row>
      </v-card>
  </v-card>
</template>
<script>
import { Decode } from "@/services";
import moment from "moment";
export default {
  data() {
    return {
      chatMessages: [],
      newMessage: '',
      form: {},
      itemsData: [],
      showSlip: "",

      headersOrder: [
        { text: "ลำดับที่", value: "index", align: "center", width: 100 },
        {
          text: "ชื่อสัตว์เลี้ยง",
          value: "petName",
          align: "center",
          width: 200,
        },
        {
          text: "พันธุ์สัตว์เลี้ยง",
          value: "petSpecies",
          align: "center",
          width: 100,
        },
        { text: "น้ำหนัก", value: "petWeight", align: "center", width: 100 },
        {
          text: "บริการ",
          value: "petType",
          align: "center",
          width: 100,
        },
        { text: "ประเภทขน", value: "petHair", align: "center", width: 100 },
        {
          text: "พฤติกรรมส่วนตัว",
          value: "petMood",
          align: "center",
          width: 100,
        },
        {
          text: "โรคประจำตัว",
          value: "petSymptom",
          align: "center",
          width: 100,
        },
        {
          text: "บริการเสริม",
          value: "serviceType",
          align: "center",
          width: 100,
        },
        {
          text: "สางขน",
          value: "serviceHair",
          align: "center",
          width: 100,
        },
        {
          text: "ไถเท้า/ท้อง/ก้น",
          value: "serviceCut",
          align: "center",
          width: 100,
        },
        {
          text: "บีบต่อม",
          value: "serviceTom",
          align: "center",
          width: 100,
        },
        {
          text: "ตัดเล็บ",
          value: "serviceFinger",
          align: "center",
          width: 100,
        },
        {
          text: "แปรงฟัน",
          value: "serviceTooth",
          align: "center",
          width: 100,
        },
        {
          text: "สปาเกลือ (บำรุงขน)",
          value: "serviceSpa",
          align: "center",
          width: 100,
        },
        {
          text: "อาบน้ำเชื้อรา",
          value: "serviceBath",
          align: "center",
          width: 100,
        },
      ],
      chats: []
    };
  },
  created() {
    this.getOne();
    this.getChats();

    this.chatInterval = setInterval(() => {
      this.getChats();
    }, 5000);
  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed to avoid memory leaks
    clearInterval(this.chatInterval);
  },
  methods: {
    convertDateService(val) {
      return moment(val).format("DD/MM/YYYY");
    },
    onClickImg(url){
      window.open(url, '_blank');
    },
    goMap(){
      var url = `https://www.google.com/maps?q=${this.form.lat},${this.form.lng}`;

      // Open the URL in a new tab
      window.open(url, '_blank');
    },
    formatDate(date){
      return moment(date).format("MM/DD/YYYY HH:mm");
    },
    async sendMessage(sender) {
      if (this.newMessage.trim() !== '') {
        

        let chatData = {
          msg: this.newMessage,
          msgType: 'TEXT',
          chatType: 'partner',
          transactionId: this.$router.currentRoute.query.id
        }

        // this.chatMessages.push({ text: this.newMessage, sender: sender });
        // // this.chatMessages.push({ text: 'จ้า', sender: 'user' });
        this.newMessage = '';

        const response = await this.axios.post(
        `${process.env.VUE_APP_API}/transaction/createChats`, chatData);

        if (response.data.response_status == "SUCCESS") {
            this.getChats();
            console.log("success")
          }

      }
    },
    async getChats() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/transaction/getChatsByTrxId?transactionId=${this.$router.currentRoute.query.id}`
      );
      this.chats = response.data.data;
      this.chatMessages = response.data.data;
      // console.log("chats", this.chats)
    },
    async getOne() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/transaction/${this.$router.currentRoute.query.id}`
      );
      this.form = response.data.data;
      this.form.firstName = response.data.data.user.firstName;
      this.form.lastName = response.data.data.user.lastName;
      this.form.tel = response.data.data.user.tel;
      this.form.email = response.data.data.user.email;
      this.form.partnerFirstName = response.data.data.partner.firstName;
      this.form.partnerLastName = response.data.data.partner.lastName;
      this.form.partnerEmail = response.data.data.partner.email;
      this.form.partnerTel = response.data.data.partner.tel;
      this.showSlip = response.data.data.slipUrl;
      this.form.lat = response.data.data.lat;
      this.form.lng = response.data.data.lng;
      this.form.requestDate = this.convertDateService(response.data.data.requestDate);

      this.itemsData = response.data.data.transactionItems;
      console.log("this.form", this.form);
      for (let i in this.itemsData) {
        this.itemsData[i].index = parseInt(i) + 1;
      }
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage1 = URL.createObjectURL(element);
        };
      }
    },
    showPicture2(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName2 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic2 = reader.result;
          this.showImage2 = URL.createObjectURL(element);
        };
      }
    },
    showPicture3(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName3 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic3 = reader.result;
          this.showImage3 = URL.createObjectURL(element);
        };
      }
    },
    showPicture4(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName4 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic4 = reader.result;
          this.showImage4 = URL.createObjectURL(element);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    changePic2() {
      document.getElementById("picTure2").click();
    },
    changePic3() {
      document.getElementById("picTure3").click();
    },
    changePic4() {
      document.getElementById("picTure4").click();
    },
    RemoveImage1(index, item) {
      this.pic1 = [];
      this.picture_1 = "";
      this.showImage1 = "";
      this.imageName1 = "";
    },
    RemoveImage2(index, item) {
      this.pic2 = [];
      this.picture_2 = "";
      this.showImage2 = "";
      this.imageName2 = "";
    },
    RemoveImage3(index, item) {
      this.pic3 = [];
      this.picture_3 = "";
      this.showImage3 = "";
      this.imageName3 = "";
    },
    RemoveImage4(index, item) {
      this.pic4 = [];
      this.picture_4 = "";
      this.showImage4 = "";
      this.imageName4 = "";
    },
    cancel() {
      this.$router.push("ManageDealer");
    },
    goBack() {
      this.$router.push("ManageWorkInformation");
    },
    async submit() {
      var user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      // const auth = {
      //   headers: { Authorization: `Bearer ${user.token}` },
      // };
      console.log("userdata", user);
      const data = {
        ...this.form,
      };
      this.$swal({
        text: "คุณต้องการยืนยันบันทึกข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ยืนยันข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          // var user = JSON.parse(
          //   Decode.decode(localStorage.getItem("DDLotAdmin"))
          // );
          // const auth = {
          //   headers: { Authorization: `Bearer ${user.token}` },
          // };
          delete data.requestDate;
          const response = await this.axios.put(
            `${process.env.VUE_APP_API}/transaction/updateTransaction/${this.form.id}`,
            data
            // auth
          );
          console.log(response);

          if (response.data.response_status == "SUCCESS") {
            this.$swal.fire({
              icon: "success",
              text: `บันทึกข้อมูลสำเร็จ`,
              showConfirmButton: false,
              timer: 1500,
            });
            // await location.reload();
            this.$router.push("ManageWorkInformation");
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.chat-message {
  margin-bottom: 8px;
}

.user-message {
  text-align: right;
}

.partner-message {
  text-align: left;
}

.chat-message.user-message .v-list-item-content {
  background-color: #cce5ff;
  padding: 8px;
  border-radius: 8px;
}

.chat-message.partner-message .v-list-item-content {
  background-color: #f1f1f1;
  padding: 8px;
  border-radius: 8px;
}
</style>

